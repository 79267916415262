.header {
  width: 100vw;
  margin: 0 auto;
  padding: 0 0 5px 0;
  position: fixed;
  z-index: 2;
  background: #ffffff;
}
.header__main-box {
  max-width: 1160px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header__button-menu {
  display: none;
  background: none;
  background-image: url(../../images/icon/menu.svg);
  width: 36px;
  height: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 5px;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  z-index: 2;
}
.header__logo {
  width: 130px;
  height: 130px;
}
.header__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 auto;
}
.header__link {
  position: relative;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  line-height: 0.8;
  margin: 0 11px;
  color: #343434;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: color linear 1s;
}
.header__link:after {
  display: block;
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #343434;
  content: "";
  transition: width 0.5s;
}
.header__link:hover {
  color: #ff72e0;
}
.header__link:hover:after {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  height: 2px;
  background-color: #ff72e0;
  content: "";
  transition: width 0.5s;
}
.header__button-menu_close {
  background: none;
  width: 15px;
  height: 15px;
  top: 26px;
  right: 17px;
  background-image: url(../../images/icon/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-size: contain;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  display: none;
}
.header__button-menu_close:hover {
  transition: all 1s linear;
  opacity: 0.5;
  transform: rotate(360deg);
}
.header__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header__tel {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 1.2;
  color: #343434;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  transition: color linear 1s;
}
.header__tel:hover {
  color: #ff72e0;
}
.header__tel-button {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #ff72e0;
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  margin: 5px 0 10px 0;
  transition: all linear 1s;
  padding: 0;
  border-bottom: #ff72e0 1px dashed;
}
.header__tel-button:hover {
  color: #343434;
  border-bottom: #343434 1px dashed;
}
.header__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.header__social-link {
  margin: 0 5px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
  transition: all linear 1s;
}
.header__social-link:nth-of-type(1) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.header__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/telega.svg);
}
.header__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/vk.svg);
}
.header__social-link:hover {
  opacity: 0.5;
  -webkit-animation: animation 2s linear;
  -moz-animation: animation 2s linear;
  animation: animation 2s linear;
}
@keyframes animation {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-30deg);
  }
  66% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 1198px) {
  .header__link {
    margin: 0 7px;
  }
}
@media screen and (max-width: 1142px) {
  .header__link {
    margin: 0 5px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1051px) {
  .header__link {
    font-size: 17px;
  }
  .header__tel {
    font-size: 20px;
  }
}
@media screen and (max-width: 973px) {
  .header__link {
    font-size: 25px;
    margin: 0 0 21px 0;
  }
  .header__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100vh;
    top: 0;
    left: -100%;
    padding: 50px 24px 0 24px;
    opacity: 0;
    z-index: 4;
    transition: all linear 2s;
  }
  .header__menu_mob_active {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all linear 1s;
  }
  .header__button-menu {
    display: block;
  }
  .header__button-menu_close {
    display: block;
  }
}
@media screen and (max-width: 418px) {
  .header__logo {
    width: 96px;
    height: 96px;
  }
  .header__tel {
    font-size: 15px;
  }
  .header__tel-button {
    font-size: 15px;
  }
  .header__button-menu {
    display: block;
    width: 30px;
    height: 12px;
  }
  .header__social-link {
    position: fixed;
    margin: 0;
    right: 5px;
    z-index: 2;
  }
  .header__social-link:nth-of-type(3) {
    bottom: 5px;
  }
  .header__social-link:nth-of-type(2) {
    bottom: 46px;
  }
  .header__social-link:nth-of-type(1) {
    bottom: 87px;
  }
}

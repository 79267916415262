.footer {
  background-color: #ffffff;
  padding: 50px 24px 0 24px;
  margin: 0 auto;
}
.footer__box {
  max-width: 1161px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}
.footer__logo {
  width: 130px;
  height: 130px;
}
.footer__title {
  font-family: "GothamPro";
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #646464;
  margin: 0 0 23px 0;
  text-align: left;
  text-transform: uppercase;
}
.footer__menu {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 auto;
}
.footer__about-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer__link {
  position: relative;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 0.8;
  color: #343434;
  cursor: pointer;
  text-decoration: none;
  transition: color linear 1s;
  margin: 0 0 16px 0;
}
.footer__link:after {
  display: block;
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: #343434;
  content: "";
  transition: width 0.5s;
}
.footer__link:hover {
  color: #ff72e0;
}
.footer__link:hover:after {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  background-color: #ff72e0;
  content: "";
  transition: width 0.5s;
}
.footer__subtitle {
  max-width: 212px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 0.8;
  color: #343434;
  margin: 0 0 16px 0;
  text-align: left;
}
.footer__policy {
  max-width: 284px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #ff72e0;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  border: none;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px dashed #ff72e0;
  cursor: pointer;
  transition: opacity linear 1s;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  -webkit-border-bottom: 1px dashed #ff72e0;
  -moz-border-bottom: 1px dashed #ff72e0;
  -o-border-bottom: 1px dashed #ff72e0;
}
.footer__policy:hover {
  color: #343434;
}
.footer__oferta {
  max-width: 242px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #ff72e0;
  margin: 10px 0 0 0;
  padding: 0;
  background: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  border: none;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px dashed #ff72e0;
  cursor: pointer;
  transition: opacity linear 1s;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  -webkit-border-bottom: 1px dashed #ff72e0;
  -moz-border-bottom: 1px dashed #ff72e0;
  -o-border-bottom: 1px dashed #ff72e0;
}
.footer__oferta:hover {
  color: #343434;
}
.footer__tel-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
.footer__tel {
  text-align: center;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: #343434;
  cursor: pointer;
  text-decoration: none;
  margin: 0 auto;
  transition: color linear 1s;
}
.footer__tel:hover {
  color: #ff72e0;
}
.footer__tel-button {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #ff72e0;
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  margin: 5px auto 10px auto;
  transition: all linear 1s;
  border-bottom: #ff72e0 1px dashed;
}
.footer__tel-button:hover {
  color: #343434;
  border-bottom: #343434 1px dashed;
}
.footer__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 auto 5px auto;
}
.footer__social-link {
  margin: 0 5px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
  transition: all linear 1s;
}
.footer__social-link:nth-of-type(1) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.footer__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/telega.svg);
}
.footer__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/vk.svg);
}
.footer__social-link:hover {
  opacity: 0.5;
  -webkit-animation: animation 2s linear;
  -moz-animation: animation 2s linear;
  animation: animation 2s linear;
}
.footer_svp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity linear 1s;
  margin: 0 0 10px 0;
}
.footer_svp:hover {
  transition: opacity linear 1s;
  opacity: 1;
}
.footer__svp-logo {
  width: 50px;
}
.footer__svp-text {
  width: 91px;
  margin: 0;
}
@media screen and (max-width: 988px) {
  .footer__box {
    justify-content: center;
  }
  .footer__policy {
    max-width: 201px;
    font-size: 10px;
  }
  .footer__oferta {
    max-width: 173px;
    font-size: 10px;
  }
  .footer__logo {
    margin: 0 30px 0 0;
  }
  .footer__menu-box {
    margin: 0 30px 0 0;
  }
  .footer__tel-box {
    margin: 0 30px 0 0;
  }
  .footer__about-box {
    margin: 0 30px 0 0;
  }
}
@media screen and (max-width: 762px) {
  .footer__box {
    max-width: 354px;
    justify-content: space-between;
  }
  .footer__logo {
    order: 0;
    margin: 0 0 30px 0;
  }
  .footer__tel-box {
    order: 1;
    margin: 25px 0 0 0;
  }
  .footer__menu-box {
    order: 2;
    margin: 0 0 0 20px;
  }
  .footer__about-box {
    order: 3;
    margin: 0 0 0 48px;
  }
}
@media screen and (max-width: 402px) {
  .footer__box {
    max-width: 314px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer__logo {
    display: none;
  }
  .footer__tel-box {
    order: 0;
    margin: 0;
  }
  .footer__menu-box {
    order: 1;
    margin: 20px 0 0 0;
  }
  .footer__about-box {
    order: 2;
    margin: 20px 0 0 0;
    align-items: center;
  }
  .footer__title {
    text-align: center;
  }
  .footer__link {
    text-align: center;
    margin: 0 auto 16px auto;
  }
  .footer__subtitle {
    text-align: center;
    margin: 0 auto 16px auto;
  }
}

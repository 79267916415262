.section__title {
  max-width: 754px;
  margin: 0 auto 50px auto;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #636363;
  text-align: center;
}
.section__title-span {
  color: #ff72e0;
}
@media screen and (max-width: 1076px) {
  .section__title {
    font-size: 35px;
  }
}
@media screen and (max-width: 452px) {
  .section__title {
    font-size: 25px;
    margin: 0 0 20px;
  }
}

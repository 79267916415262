.oferta {
  padding: 32px 50px 0;
  user-select: none;
}
.oferta__text {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #343434;
  text-align: justify;
  margin: 0 auto 20px auto;
}
.oferta__list {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #343434;
  text-align: justify;
  margin: 0 auto 20px auto;
}
.oferta__title {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: #343434;
  text-align: center;
  margin: 0 auto 20px auto;
}
@media screen and (max-width: 768px) {
  .oferta__title {
    font-size: 15px;
  }
  .oferta__text {
    font-size: 14px;
  }
  .oferta__list {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .oferta {
    padding: 24px 40px 0;
  }
}
